import request from '../../../utils/request'
const api_name = '/iotechserver/deviceType'

export default {
    getDeviceTypeList(current, limit, deviceTypeQuery) {
        return request({
            url: `${api_name}/pageDeviceType/${current}/${limit}?name=` + deviceTypeQuery.name,
            method: 'get',
        })
    },
    addDeviceType(formData) {
        return request({
            headers: {
                'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
            },
            url: `${api_name}/addDeviceType`,
            method: 'post',
            data: formData
        })
    },
    removeDeviceType(id) {
        return request({
            url: `${api_name}/removeDeviceType/${id}`,
            method: 'delete',
        })
    },
    updateDeviceType(formData) {
        return request({
            headers: {
                'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
            },
            url: `${api_name}/updateDeviceType`,
            method: 'post',
            data: formData
        })
    },
    getAllDeviceType(deviceTypeInfo) {
        return request({
            url: `${api_name}/getAllDeviceType`,
            method: 'get',
            data: deviceTypeInfo
        })
    },
    getDeviceInfoByTypeId(typeId){
        return request({
            url: `${api_name}/getDeviceInfoByTypeId/${typeId}`,
            method: 'get'
        })
    }


}